.guide {
  &-banner {
    margin-top: 0;

    &-text {
      white-space: normal;
      color: var(--itrend--gray-500);
      font-size: var(--body-small);
    }
  }

  &-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--body-medium);
    color: var(--itrend--secondary-500);
  }

  &-content {
    &-block {
      ion-item {
        width: 100% !important;
        --border-style: solid !important ;
        margin-top: 0;
      }
    }
  }
}
