.alert-card {
  background-color: var(--itrend--white);
  box-shadow: none;
  border: 1px solid var(--itrend--blueGray-100);
  border-radius: var(--border-radius-xs);
  margin-bottom: calc(var(--space-3) - var(--space-1));

  &-inactive {
    background-color: var(--itrend--blueGray-50);
  }

  &-id {
    font-weight: var(--font-weight-regular);
    font-size: var(--caption);
  }

  &-chip {
    font-weight: var(--font-weight-bold);
    padding: var(--space-1) calc(var(--space-3) - var(--space-1));
    font-size: var(--caption);

    &-roja {
      background-color: var(--error-light);
      color: var(--error-dark);
    }

    &-amarilla {
      background-color: var(--warning-light);
      color: var(--warning-dark);
    }

    &-tempranapreventiva {
      background-color: var(--success-light);
      color: var(--success-dark);
    }

    &-inactive {
      background-color: var(--itrend--blueGray-100) !important;
      color: var(--itrend--blueGray-500) !important;
      border-color: var(--itrend--blueGray-500) !important;
    }
  }

  &-title {
    font-size: var(--body-medium);
    font-weight: var(--font-weight-regular);
    color: var(--itrend--gray-800);

    &-inactive {
      text-decoration: line-through;
    }
  }

  &-locations {
    margin-left: var(--space-3);

    &-caption {
      color: var(--itrend--gray-500);
      font-weight: var(--font-weight-regular);
      font-size: var(--body-small);
      margin-bottom: var(--space-1);

      &-inactive {
        text-decoration: line-through;
      }
    }

    &-text {
      color: var(--itrend--gray-800);
      font-weight: var(--font-weight-bold);
      font-size: var(--body-medium);

      &-inactive {
        text-decoration: line-through;
      }
    }
  }

  &-datetime {
    border-top: 1px solid var(--itrend--blueGray-100);
    border-bottom: 1px solid var(--itrend--blueGray-100);
    padding: var(--space-3) 0;
    margin: 0;
    font-weight: var(--font-weight-regular);
    font-size: var(--caption);
    color: var(--itrend--gray-500);

    & > ion-row > ion-col:nth-child(2) {
      text-align: right;
    }
  }

  &-buttons {
    ion-spinner.fav-spinner {
      color: var(--itrend--secondary-500) !important;
      --color: var(--itrend--secondary-500) !important;
    }

    &-no-paddings {
      padding: 0;
    }

    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-share {
      color: var(--itrend--secondary-800);
      height: var(--headline-medium);
      margin-right: var(--space-2);
    }

    &-fav {
      color: var(--itrend--secondary-500);
      height: var(--headline-medium);

      &-inactive {
        color: var(--itrend--secondary-800);
      }
    }

    &-seemore {
      font-weight: var(--font-weight-regular);
      font-size: var(--body-medium);
      color: var(--itrend--secondary-500);
      text-transform: none;
    }
  }
}

.alert-card-share {
  & ion-button.circle {
    --border-radius: 50%;
    width: 59px;
    height: 59px;
    --background: white;
    box-sizing: border-box;
    border-color: var(--itrend--secondary-500);
  }

  & ion-icon.rotated {
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    fill: var(--itrend--secondary-500);
  }
}

.smth-new-indicator {
  position: absolute;
  background-color: var(--itrend--secondary-500);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  right: -6px;
  top: -6px;

  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
