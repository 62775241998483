.home-widgets-wrap-bystatus {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  // margin-top: 20px;
}

.home {
  &-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--body-medium);
    color: var(--itrend--secondary-500);
    padding-inline: 0 !important;
  }

  &-content {
    &-block {
      margin-top: var(--space-2);
      padding: var(--space-4);
      background-color: var(--itrend--white);
    }
  }
}

.home-widgets-wrap-byzone {
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  gap: 5px;
}

.home-see-more-button {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  font-size: var(--headline-small);
  /* margin-top: -50px; */
}

ion-thumbnail {
  // width: 100%;
  height: 100%;
  // border-radius: 8px;
}

ion-skeleton-text {
  --border-radius: var(--space-1);
  --background: rgba(0, 0, 0, 1);
  --background-rgb: 0, 0, 0;
}

ion-col {
  padding: 0;
}
