.alert-details,
.event-details {
  &-no-events {
    &-container {
      border: 1px dashed var(--itrend--gray-100);
      border-radius: var(--border-radius-sm);
      padding: 0 var(--space-2);
    }
  }

  &-container-active {
    &-tempranapreventiva {
      border-top: var(--space-3) solid var(--tempranapreventiva-color);
    }

    &-amarilla {
      border-top: var(--space-3) solid var(--amarilla-color);
    }

    &-roja {
      border-top: var(--space-3) solid var(--roja-color);
    }
  }

  &-content {
    &-block {
      margin-top: var(--space-2);
      padding: var(--space-4);
      background-color: var(--itrend--white);

      &-inactive {
        margin-top: var(--space-2);
        padding: var(--space-4);
        background-color: var(--itrend--blueGray-50);
        border-bottom-width: 0 !important;
      }
    }
  }

  &-item {
    &-inactive {
      --background: var(--itrend--blueGray-50);
      border-bottom: 1px solid var(--itrend--blueGray-100);
    }

    & ion-grid {
      overflow: hidden;

      & ion-row {
        overflow: hidden;

        &ion-col {
          overflow: hidden;
        }
      }
    }

    &-grid {
      display: grid;
      grid-row-gap: var(--space-2);
      margin-bottom: var(--space-3);
      margin-bottom: 0;
      padding: 0;

      & > ion-row {
        border-radius: var(--border-radius-sm);
        border: 1px solid var(--itrend--gray-100);
        padding: var(--space-3) var(--space-4);

        & ion-col {
          font-weight: var(--font-weight-bold);
          font-size: var(--body-large);
          color: var(--itrend--gray-900);

          & ul.ion-no-margin {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    &-header1 {
      font-weight: var(--font-weight-bold);
      font-size: var(--headline-small);
      color: var(--itrend--gray-800);
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }

    &-header2 {
      font-weight: var(--font-weight-bold);
      font-size: var(--body-large);
      color: var(--itrend--gray-800);
      margin-top: 0;
    }

    &-text {
      &-sm {
        font-weight: var(--font-weight-regular);
        font-size: var(--caption);
        color: var(--itrend--gray-500);
      }

      &-md {
        font-weight: var(--font-weight-regular);
        font-size: var(--body-small);
        color: var(--itrend--gray-500);
      }

      &-lg {
        font-weight: var(--font-weight-regular);
        font-size: var(--body-medium);
        color: var(--itrend--gray-500);
      }
    }

    & .alert-card-buttons {
      & > div {
        margin-left: 0;
      }
    }

    & .alert-card-locations {
      margin: 0;

      &-caption {
        font-size: var(--body-large);
        margin-top: var(--space-0);
      }

      &_text {
        font-size: var(--body-medium);
      }
    }
  }

  &-sae {
    &-button {
      width: 100%;

      &:hover {
        --background: transparent !important;
      }

      &-inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-label {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: right;
          flex: 1;
          padding: 0 10px;
          font-weight: 400;
          font-size: var(--body-small);
          color: var(--itrend--gray-500);
        }
      }

      &::part(native) {
        // padding-top: var(--space-4);
        // padding-bottom: var(--space-4);

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.map-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &-hidden {
    display: none;
  }
}

.alert-afectations,
.event-afectations {
  &-grid-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-left: var(--space-4);

      & p {
        &:first-of-type {
          margin-right: var(--space-4);
        }

        &:last-of-type {
          flex-grow: 1;
          text-align: right;
        }
      }
    }
  }
}
