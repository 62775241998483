.logout-button {
  font-weight: var(--font-weight-regular);
  font-size: var(--body-medium);
  color: var(--itrend--secondary-500);

  &::part(native) {
    padding-left: 0;
  }
}

.main-menu-item::part(native) {
  border-radius: var(--border-radius-xs);
}
