.custom-chip-with-no-ripple-effect {
  background-color: var(--itrend--blueGray-50);
  color: var(--itrend--secondary-500);
  border: 2px solid var(--itrend--secondary-500);
  display: flex;
  flex-wrap: nowrap;
  font-weight: var(--font-weight-bold);
  font-size: var(--caption);
  border-radius: 20px;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 5px;
  margin-top: 5px;
  margin-right: 5px;

  & ion-icon {
    width: 20px;
    height: 20px;
    padding-left: 5px;
  }
}
