.filter-menu {
  &-header {
    box-shadow: none;
    border-bottom: none;
  }

  &-bytype {
    &-typechip {
      margin: 0;
      width: 33.3%;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      height: auto;
      font-weight: var(--font-weight-bold);
      font-size: var(--body-small);

      color: #51585c !important;
      border: 1px solid var(--itrend--blueGray-100) !important;

      --background: transparent;
      &-active {
        color: var(--itrend--white) !important;
        background-color: var(--itrend--secondary-500) !important;
      }

      &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-of-type(2) {
        border-radius: 0;
        border-left: none !important;
        border-right: none !important;
      }

      &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
