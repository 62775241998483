/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** padding **/
  --ion-padding: 16px;

  /* --ripple-color: #3880ff !important; */

  /* SP Digital */
  --itrend--brand-DarkSlateBlue: #3d497e;
  --itrend--brand-IndigoDye: #4b57a0;
  --itrend--brand-BlueStone: #1c5f76;
  --itrend--primary-50: #e1e2f0;
  --itrend--primary-100: #c5c8e5;
  --itrend--primary-200: #a9aedb;
  --itrend--primary-300: #8f97d0;
  --itrend--primary-400: #727fc5;
  --itrend--primary-500: #5869b1;
  --itrend--primary-600: #46538f;
  --itrend--primary-700: #3d497e;
  --itrend--primary-800: #252d4f;
  --itrend--primary-900: #161b30;
  --itrend--secondary-50: #dae3fb;
  --itrend--secondary-100: #b6cafc;
  --itrend--secondary-200: #8eb1fd;
  --itrend--secondary-300: #609bfd;
  --itrend--secondary-400: #1585f5;
  --itrend--secondary-500: #0f69c4;
  --itrend--secondary-600: #0b58a6;
  --itrend--secondary-700: #084d92;
  --itrend--secondary-800: #082f5c;
  --itrend--secondary-900: #081c38;
  --itrend--tertiary-50: #c6e8f8;
  --itrend--tertiary-100: #84d5f7;
  --itrend--tertiary-200: #41bfeb;
  --itrend--tertiary-300: #37a7cd;
  --itrend--tertiary-400: #2e8eae;
  --itrend--tertiary-500: #247591;
  --itrend--tertiary-600: #1c5f76;
  --itrend--tertiary-700: #175267;
  --itrend--tertiary-800: #0e3340;
  --itrend--tertiary-900: #091e26;

  /* Color decorative */
  --itrend--decorativePurple-50: #eeddf7;
  --itrend--decorativePurple-100: #e1bcf5;
  --itrend--decorativePurple-200: #d29cf3;
  --itrend--decorativePurple-300: #c37def;
  --itrend--decorativePurple-400: #b25beb;
  --itrend--decorativePurple-500: #a746e9;
  --itrend--decorativePurple-600: #8224c1;
  --itrend--decorativePurple-700: #721eaa;
  --itrend--decorativePurple-800: #47126b;
  --itrend--decorativePurple-900: #2c0b42;
  --itrend--decorativeYellow-50: #f5e447;
  --itrend--decorativeYellow-100: #d0c119;
  --itrend--decorativeYellow-200: #c0b213;
  --itrend--decorativeYellow-300: #a79b0f;
  --itrend--decorativeYellow-400: #8e840b;
  --itrend--decorativeYellow-500: #766d08;
  --itrend--decorativeYellow-600: #5f5705;
  --itrend--decorativeYellow-700: #524c04;
  --itrend--decorativeYellow-800: #332f05;
  --itrend--decorativeYellow-900: #1f1c06;

  /* Color grises */
  --itrend--blueGray-50: #eceff1;
  --itrend--blueGray-100: #c3cacf;
  --itrend--blueGray-200: #a8b2b9;
  --itrend--blueGray-300: #909ca3;
  --itrend--blueGray-400: #79848b;
  --itrend--blueGray-500: #646d73;
  --itrend--blueGray-600: #51585c;
  --itrend--blueGray-700: #474c50;
  --itrend--blueGray-800: #2b2f31;
  --itrend--blueGray-900: #141d22;
  --itrend--gray-50: #eaeaea;
  --itrend--gray-100: #c9c9c9;
  --itrend--gray-200: #b0b0b0;
  --itrend--gray-300: #9a9a9a;
  --itrend--gray-400: #828282;
  --itrend--gray-500: #6c6c6c;
  --itrend--gray-600: #575757;
  --itrend--gray-700: #4b4b4b;
  --itrend--gray-800: #2e2e2e;
  --itrend--gray-900: #1b1b1b;
  --itrend--white: #fff;

  /* Colores de sistema */
  --warning-base: #ebbc46;
  --warning-light: #fbdea2;
  --warning-dark: #815025;
  --success-base: #018484;
  --success-light: #cce5c6;
  --success-dark: #3e6946;
  --error-base: #e22c2c;
  --error-light: #f2d6d6;
  --error-dark: #880a0a;

  /* Tipografía */
  --base-font-family: 'Lato', sans-serif;
  --display-large: 56px;
  --display-medium: 36px;
  --headline-small: 28px;
  --headline-large: 24px;
  --headline-medium: 22px;
  --headline-small: 20px;
  --body-large: 18px;
  --body-medium: 16px;
  --body-small: 14px;
  --caption: 12px;
  --overline: 10px;

  /* Bordes */
  --border-radius-xl: 32px;
  --border-radius-lg: 24px;
  --border-radius-md: 16px;
  --border-radius-sm: 8px;
  --border-radius-xs: 4px;
  --breakpoint-lg: 1280px;
  --breakpoint-md: 960px;
  --breakpoint-sm: 480px;

  /* Layout Space */
  --space-10: 248px;
  --space-9: 208px;
  --space-8: 128px;
  --space-7: 96px;
  --space-6: 48px;
  --space-5: 32px;
  --space-4: 24px;
  --space-3: 16px;
  --space-2: 8px;
  --space-1: 4px;
  --space-0: 2px;

  /* others */
  /* --tempranapreventiva-color: #018484; */
  --tempranapreventiva-color: #30B677;
  --amarilla-color: #ebbc46;
  --roja-color: #e22c2c;

  /* font weights */
  /* --font-weight-light: 300; */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-prebold: 600;
  --font-weight-bold: 700;

  /* map component vars */
  --map-width: 35%;
  --regions-list-width: 30%;
  
  --alert-color-red: #ff8383;
  --alert-color-yellow: #fff500;
  --alert-color-green: #72ff88;
  --alert-color-empty: #717171;

  --roboto-font-family: 'Roboto', sans-serif;

  --bottom-tab-height: 62px;
}

/* @media only screen and (min-width: 768px) and (min-height: 600px){ */
ion-modal {
  --width: 100% !important;
  --height: 100% !important;
}
/* } */

/* :root{
  --ion-safe-area-bottom: 14px;
} */

/* .ios{
  --ion-safe-area-top : -1 !important;
  --ion-safe-area-bottom: 14px;
} */