.tutorial-card {
  width: auto;
  height: 100%;
  margin: var(--space-2);
  padding: var(--space-2);
  border-radius: var(--border-radius-sm);
  box-shadow: 2px 4px 12px 0px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & ion-card-title {
    padding-top: 2rem;
    text-align: center;
  }

  & ion-img {
    width: auto;
    height: 100% !important;
    margin: auto;
    padding: 10%;
    overflow: auto;
  }
}

.swiper {
  padding-bottom: var(--space-6) !important;
  padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  &-wrapper {
    height: 100% !important;
  }

  &-slide {
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  &-pagination-bullet {
    height: 12px;
    width: 12px;
  }
}
