.permission-banner {
  background-color: var(--itrend--secondary-50);
  margin: 0;
  align-items: center;
  padding-left: var(--space-4);
  padding-right: var(--space-4);

  & ion-item {
    --background: var(--itrend--secondary-50) !important;
  }

  & ion-text {
    color: var(--itrend--primary-800);
  }
}
