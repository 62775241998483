.leaflet-fullscreen {
  width: 100% !important;
}

// .leaflet-container {
//   height: 100vh;
//   width: 100vw;
// }

.map-regions-list {
  &-button {
    &:hover {
      --background: transparent !important;
    }

    &-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: right;
        flex: 1;
        padding: 0 10px;
        font-weight: 400;
        font-size: var(--caption);
        color: var(--itrend--gray-500);
      }
    }

    &::part(native) {
      &:hover {
        background-color: transparent;

        // --background-activated: transparent;
        // --background-focused: var(--ion-color-primary, #3880ff);
        // --background-hover: var(--ion-color-primary, #3880ff);
        // --background-activated-opacity: 0;
        // --background-focused-opacity: 0.12;
        // --background-hover-opacity: 0.04;
      }
    }
  }
}

.modal-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.regional-alert-block {
  // height: 10%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 10px;

  & p {
    margin: 0;
    padding: 0;
  }

  border-bottom: 1px solid var(--itrend--blueGray-300);

  &-roja {
    // background-color: var(--error-light);
    background-color: var(--itrend--blueGray-50);
  }

  &-amarilla {
    // background-color: var(--warning-light);
    background-color: var(--itrend--gray-50);
  }

  &-temprana-preventiva {
    // background-color: var(--success-light);
    background-color: var(--itrend--gray-50);
  }
}

.map-container-block {
  // height: 60%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-alerts {
  &-block {
    height: 30%;
    overflow-y: scroll;
    // border-top: 1px solid var(--itrend--blueGray-400);
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 10px;
    border-bottom: 1px solid var(--itrend--blueGray-300);
    background-color: var(--itrend--gray-50);
    height: 50px;

    & p {
      margin: 0;
      padding: 0;
    }

    &-roja {
      background-color: var(--error-base);
    }

    &-amarilla {
      background-color: var(--warning-base);
    }

    &-temprana-preventiva {
      background-color: #30b677;
      // background-color: var(--success-base);
    }
  }
}

// .leaflet-tooltip-own {
//   position: absolute;
//   padding: 4px;
//   background-color: rgba(0, 0, 0, 0.4);
// }

.leaflet-tooltip-labelstyle {
  background-color: transparent;
}

.leaflet-tooltip.labelstyle {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
  font-weight: bold;
  font-size: 12px;
}

.alert-button-caption {
  color: var(--itrend--gray-700);
  font-size: 12px;
  font-weight: 700;
}

.alert-button-subcaption {
  color: var(--itrend--gray-700);
  font-size: 12px;
  font-weight: 400;
}
