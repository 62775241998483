ion-modal {
  &#map-legend-modal {
    --height: fit-content;
    --width: 90% !important;
    // --max-height: 300px !important;
    --max-width: 350px !important;
    --border-radius: var(--border-radius-sm);
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  &#map-legend-modal::part(backdrop) {
    background-color: #373737;
    opacity: 0.65 !important;
  }

  &#map-legend-modal::part(content) {
    height: auto;
    padding-bottom: var(--space-4) !important;
  }
}

.legend {
  &-modal {
    &-container {
      --background: var(--itrend--white);
    }

    &-header {
      font-size: var(--body-large);
      color: var(--itrend--blueGray-900);
      font-weight: var(--font-weight-bold);
    }

    &-elements {
      &-element {
        display: flex;
        margin-top: var(--space-4);
        justify-content: space-between;
        align-items: center;
        color: var(--itrend--blueGray-900);
        font-size: var(--body-small);
        font-weight: var(--font-weight-regular);

        &-chip {
          &:first-of-type {
            background-color: var(--error-base);
            margin-right: 5px;
          }

          &:nth-of-type(2) {
            background-color: var(--warning-base);
            color: var(--warning-dark);
            margin-right: 5px;
          }

          &:last-of-type {
            background-color: #30b677;
          }

          color: var(--itrend--white);
          border-radius: var(--border-radius-xs);
          font-size: var(--overline);
          font-weight: var(--font-weight-bold);
          height: var(--headline-small);
          width: var(--headline-small);
          display: flex;
          justify-content: center;
          align-items: center;

          &-big {
            width: 70px;
            justify-content: left;
            position: absolute;
            text-align: right;
            padding: 0 5px;

            &:first-of-type {
              border: 1px solid var(--itrend--blueGray-400);
              background-color: white;
              left: 0;
              top: 0;
              z-index: 15;
              color: var(--itrend--blueGray-900);
            }

            &:nth-of-type(2) {
              background-color: var(--error-base);
              left: 5px;
              top: 5px;
              z-index: 14;
            }

            &:nth-of-type(3) {
              background-color: var(--warning-base);
              color: var(--warning-dark);
              left: 10px;
              top: 10px;
              z-index: 13;
            }

            &:last-of-type {
              background-color: #30b677;
              left: 15px;
              top: 15px;
              z-index: 12;
            }
          }
        }

        &-arrow {
          margin-left: 5px;
          margin-right: 10px;
        }

        &-caption {
        }
      }
    }
  }
}

.arrow-right {
  white-space: nowrap;

  &:after {
    content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-left: 4px solid var(--itrend--secondary-400);
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    vertical-align: middle;
  }

  &:before {
    width: 30px;
    height: 2px;
    background: var(--itrend--secondary-400);
    content: "";
    display: inline-block;
    vertical-align: middle;
  }
}
