.home-widgets {
  &-bystatus {
    width: 100px;
    height: 100px;
    border: 1px solid var(--itrend--blueGray-100);
    border-radius: var(--border-radius-sm);
    box-sizing: border-box;
    color: var(--itrend--primary-700);
    padding-bottom: var(--space-2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-tempranapreventiva {
      border-top: var(--overline) solid var(--tempranapreventiva-color);
    }

    &-amarilla {
      border-top: var(--overline) solid var(--amarilla-color);
    }

    &-roja {
      border-top: var(--overline) solid var(--roja-color);
    }

    &-num {
      font-family: "Roboto", sans-serif;
      font-weight: var(--font-weight-medium);
      font-size: var(--display-large);
      margin: 0;
    }

    &-label {
      font-weight: var(--font-weight-bold);
      font-size: var(--body-medium);
      margin: 0;
    }
  }
}
