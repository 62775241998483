.regions {
  &-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--body-medium);
    color: var(--itrend--secondary-500);
    // padding: var(--space-2);
    // padding-inline: 0 !important;
  }

  &-content {
    &-block {
      margin-top: var(--space-2);
      padding: var(--space-4);
      background-color: var(--itrend--white);

      ion-item {
        margin-top: 0px;
        margin-bottom: 0px;
        --min-height: none;

        ion-label {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
