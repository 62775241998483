#leaflet-container,
.leaflet-container {
  width: 100%;
  height: 100%;
  background-color: white;
}

.leaflet-tooltip.labelstyle {
  font-weight: 700;
  font-size: 12px;
  text-shadow:
    -1px -1px 0 #fff,
    0 -1px 0 #fff,
    1px -1px 0 #fff,
    1px 0 0 #fff,
    1px 1px 0 #fff,
    0 1px 0 #fff,
    -1px 1px 0 #fff,
    -1px 0 0 #fff;
}
