* {
  font-family: var(--base-font-family) !important;
}

.text-cut-1-line {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-cut-3-line {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

ion-chip.no-click {
  pointer-events: none;
}

ion-content {
  &.ion-content-custom-background {
    --background: var(--itrend--blueGray-50);

    &-inactive {
      --background: var(--itrend--white);
    }
  }
}

ion-item,
.ion-item-but-div {
  --border-style: none;
  background-color: var(--itrend--white);
  margin-top: var(--space-2);

  &-inactive {
    background-color: var(--itrend--blueGray-50);
  }

  &.ionitem-no-margin {
    margin: 0;
  }
}

ion-grid {
  // background-color: #ffffff;
}

// ============================================================
// global classes
// ============================================================
// Headline Medium - bold
.headline-medium-bold {
  font-weight: var(--font-weight-prebold);
  font-size: var(--headline-medium);
  color: var(--itrend--blueGray-800);
}

// Caption - medium
.caption-medium {
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--caption);
}

// Body Large - bold
.body-large-bold {
  font-weight: var(--font-weight-bold);
  font-size: var(--border-large);
}

// Body Medium - medium
.body-medium-medium {
  font-weight: var(--font-weight-regular);
  font-size: var(--body-medium);
}

// Body Medium - bold
.body-medium-bold {
  font-weight: var(--font-weight-regular);
  font-size: var(--body-medium);
}

// Body Small - bold
.body-small-bold {
  font-weight: var(--font-weight-bold);
  font-size: var(--body-small);
}

// ============================================================
// ion button default styles overrides
// ============================================================
// buttons used are as follows:

// <IonButton className="home-see-more-button">
//   <IonRouterLink href={route}>Ver todas</IonRouterLink>
// </IonButton>

// <IonButton className="home-see-more-button" fill="outline">
//   <IonRouterLink href={route}>Ver todas</IonRouterLink>
// </IonButton>

// <IonButton className="home-see-more-button" shape="round">
//   <IonRouterLink href={route}>Ver todas</IonRouterLink>
// </IonButton>

// <IonButton className="home-see-more-button" shape="round" fill="outline">
//   <IonRouterLink href={route}>Ver todas</IonRouterLink>
// </IonButton>

// <IonButton className="home-see-more-button" fill="clear">
//   <IonRouterLink href={route}>Ver todas</IonRouterLink>
// </IonButton>

ion-button {
  text-transform: none;
  color: var(--itrend--white);

  & ion-router-link {
    font-weight: var(--font-weight-regular);
    font-size: var(--body-medium);
    text-align: center;
  }

  &.button-round,
  &.button-solid {
    --background: var(--itrend--secondary-500);

    & ion-router-link {
      color: var(--itrend--white);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        --background: var(--itrend--secondary-600);
      }
    }

    &:disabled {
      --background: var(--itrend--blueGray-400);
    }
  }

  &.button-clear,
  &.button-outline {
    --background: transparent;
    color: var(--itrend--secondary-500);

    & ion-router-link {
      color: var(--itrend--secondary-500);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--itrend--secondary-500);
      }
    }
    &:disabled {
      color: var(--itrend--blueGray-400);
    }
  }

  &.button-outline {
    --border-color: var(--itrend--secondary-500);
    --border-width: 1px;
    --border-style: solid;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        --border: 1px solid var(--itrend--secondary-600);
      }
    }
    &:disabled {
      --border: var(--itrend--blueGray-400);
    }
  }
}

// ============================================================
// ion chip default styles overrides
// ============================================================
// buttons used are as follows:

// <IonChip onClick={() => clearTypeFilter()}>
//   <IonLabel>Tipo de Alerta</IonLabel>
//   <IonIcon src={closeFill}></IonIcon>
// </IonChip>

// <IonChip outline={true} onClick={() => clearTypeFilter()}>
//   <IonLabel>Tipo de Alerta</IonLabel>
//   <IonIcon src={closeOutline}></IonIcon>
// </IonChip>

// <IonChip onClick={() => clearTypeFilter()}>
//   <IonLabel>Tipo de Alerta</IonLabel>
// </IonChip>

// <IonChip outline={true} onClick={() => clearTypeFilter()}>
//   <IonLabel>Tipo de Alerta</IonLabel>
// </IonChip>

ion-chip {
  --background: var(--itrend--secondary-500);
  color: var(--itrend--white);
  font-weight: var(--font-weight-bold);
  font-size: var(--caption);

  &:not(:has(ion-icon)) {
    height: 22px;
  }

  &.chip-outline {
    background-color: var(--itrend--blueGray-50);
    color: var(--itrend--secondary-500);
    border: 2px solid var(--itrend--secondary-500);
  }
}

// ============================================================
// ion header styles
// ============================================================
ion-header {
  & ion-toolbar {
    --background: var(--itrend--primary-700);
    color: var(--itrend--blueGray-50);

    & ion-title {
      font-style: normal;
      font-weight: var(--font-weight-bold);
      font-size: var(--body-large);
    }
  }
}

// ============================================================
// main menu styles
// ============================================================
.main-menu {
  &-label {
    color: var(--itrend--blueGray-600);
  }

  &-item {
    color: var(--itrend--blueGray-800);

    &.active > ion-item {
      --background: var(--itrend--primary-600);
      border-radius: var(--border-radius-xs);
      color: var(--itrend--white);

      & > ion-label {
        font-weight: var(--font-weight-bold);
      }
    }
  }
}

// ============================================================
// tabs styles
// ============================================================
ion-tab-bar {
  gap: var(--space-4);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  border-top: none;
  height: var(--bottom-tab-height);

  &.ion-tab-bar-loading {
    // height: 66px;
  }
}

ion-tab-button {
  --color: var(--itrend--blueGray-500);
  --color-selected: var(--itrend--secondary-500);

  border-top: 4px solid var(--itrend--white);

  &.ion-tab-button-loading {
    border-top: 4px solid transparent !important;
  }

  &.tab-selected {
    border-color: var(--itrend--secondary-500);
    & .tab-unselected-icon {
      display: none;
    }
  }
  &.tab-selected .tab-selected-icon {
    display: initial !important;
  }
}

.tab-selected-icon {
  display: none;
}

// ============================================================
// ion checkbox default styles overrides
// ============================================================
ion-checkbox {
  --size: var(--headline-large);
  --border-color: var(--itrend--blueGray-900);
  --border-color-checked: var(--itrend--secondary-500);
  --background-checked: var(--itrend--secondary-500);
  margin-inline-end: var(--space-2);
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      --background: var(--itrend--gray-50);
    }
  }

  &:disabled {
    --background: var(--itrend--gray-50);

    & ion-label {
      color: var(--itrend--gray-300);
    }
  }
}

// ============================================================
// ion radio default styles overrides
// ============================================================
ion-radio {
  --color: var(--itrend--blueGray-900);
  --color-checked: var(--itrend--secondary-500);
  margin-inline-end: var(--space-2);
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      --background: var(--itrend--gray-50);
      --color: var(--itrend--blueGray-400);
    }
  }

  &:disabled {
    --background: var(--itrend--gray-50);
    --color: var(--itrend--blueGray-400);
  }
}

// ============================================================
// modal background color and opacity
// ============================================================
ion-modal {
  --backdrop-opacity: 1;
  --border-radius: var(--border-radius-sm);
}

.clear-min-height {
  --min-height: initial !important;
}

.alert-unfollow {
  & .alert-checkbox-label {
    font-weight: var(--font-weight-bold);
    font-size: var(--body-small);
    white-space: normal;
    padding-left: var(--space-5);
  }

  & .alert-checkbox-group {
    margin: 0px;
    border: 0px !important;
  }

  & .alert-message {
    padding-bottom: 0px;
  }
}

.infinite-loading-spinner ion-spinner {
  width: 33px;
  height: 33px;
  --color: #3068be;
}

.scroll-to-top {
  width: 100%;
  height: 100px;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/////////////////
// aws amplify //
div[data-amplify-authenticator] {
  height: 100vh !important;
  background-color: #082f5c;

  // fixing ios notch
  padding-top: var(--ion-safe-area-top);
}

div[data-amplify-container] {
  height: 100% !important;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;

  & > div {
    &:first-of-type {
      padding: 0 !important;
    }

    &:nth-of-type(2) {
      box-shadow: none !important;
      border-style: none !important;
    }

    &:last-of-type {
      width: 100%;
      text-align: center;
      flex-grow: 1 !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: calc(40px + var(--ion-safe-area-bottom));

      & > img {
        width: 100px;
        height: auto;
      }
    }
  }
}

form[data-amplify-form] {
  padding: 0 var(--space-4) !important;
  padding-top: 60px !important;
}

.amplify-button.amplify-field-group__control {
  &.amplify-button--primary {
    background-color: var(--itrend--secondary-500) !important;
  }

  &.amplify-button--link {
    color: var(--itrend--secondary-500) !important;
    font-weight: var(--font-weight-bold) !important;
    font-size: var(--body-medium) !important;
  }
}

// aws amplify //
/////////////////

// ios bottom iphone x fixed
// body {
//   margin-top: constant(safe-area-inset-top);
//   margin-top: env(safe-area-inset-top);
// }

// ion-tab-bar {
//   margin-bottom: calc(7px + env(safe-area-inset-top));
// }
