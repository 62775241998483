.resources {
  &-accordion {
    background-color: transparent;

    &.accordion-expanded {
      & ion-item {
        border-bottom: none !important;
      }

      & div:first-of-type {
        border-top: 1px solid var(--itrend--blueGray-100);
      }

      & div:last-of-type {
        border-bottom: 1px solid var(--itrend--blueGray-100);
      }

      &:last-of-type div:last-of-type {
        border-bottom: none;
      }
    }
  }

  &-header {
    font-weight: var(--font-weight-bold);
    font-size: var(--body-medium);
    color: var(--itrend--gray-900);

    &-item {
      background-color: transparent;
      --ion-item-background: transparent;
    }
  }

  &-subheader {
    font-weight: var(--font-weight-regular);
    font-size: var(-body-medium);
    color: var(--itrend--blueGray-500);
  }

  &-text {
    font-weight: var(--font-weight-regular);
    font-size: var(-body-medium);
    color: var(--itrend--gray-900);
  }
}
