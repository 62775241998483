.event-card {
  margin-top: var(--space-2) !important;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid var(--itrend--blueGray-100);
  border-radius: var(--border-radius-xs);

  & ion-card-content {
    padding-bottom: 0;
  }

  &-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--body-medium);
    color: var(--itrend--gray-800);
  }

  &-subtitle {
    font-weight: var(--font-weight-regular);
    font-size: var(--caption);
    color: var(--itrend--gray-500);
  }

  &-locations {
    font-weight: var(--font-weight-regular);
    font-size: var(--body-small);
    color: var(--itrend--gray-500);
  }

  &-chip {
    font-size: var(--caption);
    font-weight: var(--font-weight-bold);

    &-inactive {
      background-color: var(--itrend--blueGray-100) !important;
      color: var(--itrend--blueGray-500) !important;
      border-color: var(--itrend--blueGray-500) !important;
    }
  }

  &-badge {
    &-block {
      // & .item-native {
      --background: transparent !important;
      display: flex;
      align-items: center;
      // }
    }
    &-caption {
      font-style: normal !important;
      font-weight: var(--font-weight-regular) !important;
      font-size: var(--caption) !important;
      color: var(--itrend--gray-500) !important;
    }
    &-badge {
      background: #5869b1;
      height: var(--headline-large);
      width: var(--headline-large) !important;
      margin-left: var(--space-1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--caption);
      font-weight: var(--font-weight-bold);
      font-size: var(--caption);
      color: var(--itrend--white);
    }
  }
}
