.home-widgets {
  &-byzone {
    display: flex;
    justify-content: space-between;
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--itrend--gray-100);
    padding: var(--space-3) var(--space-4);
    align-items: center;

    &-num,
    &-label {
      font-weight: var(--font-weight-bold);
      font-size: var(--body-large);
      margin: 0;
      color: var(--itrend--blueGray-900);
    }
  }
}
