.global-loader-container {
  position: absolute;
  bottom: calc(var(--bottom-tab-height) + var(--ion-safe-area-bottom));
  left: 0;

  width: 100%;
  height: 3px;

  z-index: 99;
}

.global-loader {
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;
  // background-color: #ddd;
  margin: 4px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  &:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 4px;
    width: 40%;
    // background-color: coral;
    background-color: var(--itrend--secondary-500);
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
