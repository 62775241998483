.iframe-container {
  width: 100%;
  height: 100%;

  & iframe {
    width: 100%;
    height: 100%;
  }
}

.not-protected-content {
  &::part(scroll) {
    --offset-bottom: 8px;
  }
}
