.p-timeline {
  font-weight: 400;
  text-align: center;

  &-no-connector {
    & .p-timeline-event-connector {
      display: none !important;
    }
  }

  &-event {
    &-marker {
      margin: 5px 0;

      border-color: var(--itrend--blueGray-500) !important;
      background-color: var(--itrend--blueGray-500) !important;
      width: var(--headline-medium) !important;
      height: var(--headline-medium) !important;

      &.timeline-active {
        background-color: var(--itrend--primary-600) !important;
        border-color: var(--itrend--primary-600) !important;
      }

      &.timeline-success {
        background-color: #28ba62 !important;
        border-color: #28ba62 !important;
      }

      &.timeline-success + .p-timeline-event-connector {
        background-color: #28ba62 !important;
        border-color: #28ba62 !important;
      }
    }

    &-connector {
      background-color: #c4c4c4 !important;
    }

    &-opposite {
      flex: none !important;
      display: none !important;
    }
  }
}

.alert-timeline {
  &-event {
    &-header {
      font-weight: var(--font-weight-bold) !important;
      font-size: var(--body-medium) !important;
      color: var(--itrend--gray-800);

      margin-top: 50px !important;
    }

    &-time {
      font-weight: var(--font-weight-regular);
      font-size: var(--caption);
      color: var(--itrend--gray-500);
    }
  }
}
