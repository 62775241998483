.map {
  &-counts {
    &-container {
      display: flex;
      align-items: center;
    }
  }

  &-container {
    position: relative;
    // padding: var(--space-4) var(--space-2);
    height: 100%;
    background-color: var(--itrend--blueGray-50);
    display: flex;
    width: 100%;

    &-grid {
      width: 100%;
      padding: 0;
      height: fit-content;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      background-color: var(--itrend--white);

      padding-bottom: var(--bottom-tab-height);

      &-row {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;

        padding: 20px;

        border-bottom: 1px solid var(--itrend--blueGray-100);

        // &:first-of-type {
        //   border-top: 1px solid var(--itrend--blueGray-100);
        // }

        // & ion-ripple-effect {
        //   border-radius: var(--border-radius-xs);
        // }
      }

      &-col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  &-regions {
    &-list {
      margin-right: var(--space-4);
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-label {
        height: var(--headline-medium);
        display: flex;
        align-items: center;
        padding-left: var(--space-1);
        padding-right: var(--space-1);
        min-width: 0;
        width: 100%;
        color: var(--itrend--gray-500);
        border-radius: var(--border-radius-xs);
        border: 0px solid var(--itrend--blueGray-100);
        // text-align: right;
        // font-weight: var(--font-weight-bold);

        & p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: var(--font-weight-bold);
          width: 100%;
          font-size: var(--body-small);
        }

        &-active {
          // color: var(--itrend--gray-100);

          &-icon {
            // width: 0;
            // height: 0;
            // border-left: var(--body-small) solid transparent;
            // border-right: var(--body-small) solid transparent;
            // border-bottom: var(--body-small) solid black;
          }

          &.roja {
            // background-color: var(--error-base);
            // color: var(--itrend--white);
            // border-right: 5px solid var(--error-base);
            border-radius: 0;

            & ion-icon {
              color: var(--error-base);
            }
          }

          &.amarilla {
            // background-color: var(--warning-base);
            // color: var(--warning-dark);
            // border-right: 5px solid var(--warning-base);
            border-radius: 0;

            & ion-icon {
              color: var(--warning-base);
            }
          }

          &.temprana-preventiva {
            // background-color: #30b677;
            // color: var(--itrend--white);
            // border-right: 5px solid #30b677;
            border-radius: 0;

            & ion-icon {
              color: #30b677;
            }
          }
        }

        &-inactive {
          color: var(--itrend--blueGray-500);
          background-color: transparent;
          border: none;

          & ion-icon {
            color: transparent;
          }
        }
      }
    }
  }

  &-regions {
    &-counts {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   box-sizing: border-box;
      margin-right: var(--space-3);

      &-block {
        // width: 0;
        // height: 0;
        // border: calc(var(--headline-small) / 2) solid transparent;
        // border-top: 0;
        // border-bottom: var(--headline-small) solid red;

        position: relative;
        // background-color: var(--itrend--blueGray-50);
        text-align: left;

        &:before,
        &:after {
          content: "";
          position: absolute;
          background-color: inherit;
        }

        width: calc(var(--headline-small) * 0.6);
        height: calc(var(--headline-small) * 0.6);
        border-top-right-radius: 30%;

        &:before,
        &:after {
          width: calc(var(--headline-small) * 0.6);
          height: calc(var(--headline-small) * 0.6);
          border-top-right-radius: 30%;
        }

        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

        &:before {
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
        }
        &:after {
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
        }

        &-active {
          &.roja {
            background-color: var(--error-base);
          }

          &.amarilla {
            background-color: var(--warning-base);
          }

          &.temprana-preventiva {
            background-color: #30b677;
          }
        }

        &-inactive {
          background-color: var(--itrend--blueGray-50);
          // border: 1px solid var(--itrend--blueGray-100);
        }
      }
    }
  }

  &-provincies {
    &-counts {
      // margin-right: var(--space-2);
      display: flex;
      align-items: center;
    }
  }

  &-provincies,
  &-comunes {
    &-counts {
      display: flex;
      justify-content: center;
      box-sizing: border-box;

      // &:first-of-type {
      //   margin-right: var(--space-3);
      // }

      &-blocks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
      }

      &-block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--headline-medium);
        height: var(--headline-medium);
        border-radius: var(--border-radius-xs);
        box-sizing: border-box;
        font-weight: var(--font-weight-bold);
        line-height: 0;
        font-size: var(--overline);

        // background-color: var(--itrend--blueGray-50);
        border: 1px solid var(--itrend--blueGray-500);

        margin-right: 5px;

        &:first-of-type,
        &:nth-of-type(4) {
          // margin-right: var(--space-1);

          &.active {
            background-color: var(--error-base);
            color: var(--itrend--white);
            border: 0px solid var(--error-dark);
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(5) {
          // margin-right: var(--space-1);

          &.active {
            background-color: var(--warning-base);
            color: var(--warning-dark);
            border: 0px solid var(--warning-dark);
          }
        }

        &:nth-of-type(3),
        &:nth-of-type(6) {
          &.active {
            background-color: #30b677;
            color: var(--itrend--white);
            border: 0px solid var(--success-dark);
          }
        }
      }
    }
  }

  &-comunes {
    &-counts {
      display: flex;
      justify-content: center;

      &-blocks {
        display: flex;
        justify-content: space-between;
      }

      &-block {
        border-radius: var(--headline-small);
      }
    }
  }
}

.map-counts-container {
  display: flex;
}

ion-modal {
  &#legend-modal {
    --height: fit-content;
    --width: 90% !important;
    // --max-height: 250px !important;
    --max-width: 350px !important;
    --border-radius: var(--border-radius-sm);
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  &#legend-modal::part(backdrop) {
    background-color: #373737;
    opacity: 0.65 !important;
  }
  &#legend-modal::part(content) {
    height: auto !important;
    padding-bottom: var(--space-4) !important;
  }
}

.legend {
  &-modal {
    &-container {
      --background: var(--itrend--white);
    }

    &-header {
      font-size: var(--body-large);
      color: var(--itrend--blueGray-900);
      font-weight: var(--font-weight-bold);
    }

    &-elements {
      &-element {
        display: flex;
        margin-top: var(--space-4);
        justify-content: space-between;
        align-items: center;
        color: var(--itrend--blueGray-900);
        font-size: var(--body-small);
        font-weight: var(--font-weight-regular);

        &-chip {
          &:first-of-type {
            background-color: var(--error-base);
            margin-right: 5px;
          }

          &:nth-of-type(2) {
            background-color: var(--warning-base);
            color: var(--warning-dark);
            margin-right: 5px;
          }

          &:last-of-type {
            background-color: #30b677;
          }

          color: var(--itrend--white);
          border-radius: var(--border-radius-xs);
          font-size: var(--overline);
          font-weight: var(--font-weight-bold);
          height: var(--headline-small);
          width: var(--headline-small);
          display: flex;
          justify-content: center;
          align-items: center;

          &-big {
            width: 70px;
            justify-content: left;
            position: absolute;
            text-align: right;
            padding: 0 5px;

            &:first-of-type {
              border: 1px solid var(--itrend--blueGray-500);
              background-color: white;
              left: 0;
              top: 0;
              z-index: 15;
              color: var(--itrend--blueGray-900);
            }

            &:nth-of-type(2) {
              background-color: var(--error-base);
              left: 5px;
              top: 5px;
              z-index: 14;
            }

            &:nth-of-type(3) {
              background-color: var(--warning-base);
              color: var(--warning-dark);
              left: 10px;
              top: 10px;
              z-index: 13;
            }

            &:last-of-type {
              background-color: #30b677;
              left: 15px;
              top: 15px;
              z-index: 12;
            }
          }
        }

        &-arrow {
          margin-left: 5px;
          margin-right: 10px;
        }

        &-caption {
        }
      }
    }
  }
}

.arrow-right {
  white-space: nowrap;

  &:after {
    content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-left: 4px solid var(--itrend--secondary-500);
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    vertical-align: middle;
  }

  &:before {
    width: 30px;
    height: 2px;
    background: var(--itrend--secondary-500);
    content: "";
    display: inline-block;
    vertical-align: middle;
  }
}

#open-legend-modal {
  // width: 55px;
  // height: 55px;
  // font-size: var(--overline);
  // position: absolute;
  // bottom: 100px;
  // left: var(--space-3);
  // right: -8px;
  // margin: 0;
  --background: transparent;
  // color: var(--itrend--blueGray-900);
  // --border-width: 1px;
  --border-style: none;
  --box-shadow: none;
  // --border-color: var(--itrend--blueGray-100);
  // --border-radius: 4px 0 0 4px !important;
}
